import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import AboutDetails from "../components/AboutDetails"
import CalltoAction from "../components/CalltoAction"

const AboutPage = ({ data }) => (
  <Layout>
    <SEO title="About" />
    <AboutDetails data={data} />
    <CalltoAction />
  </Layout>
)

export default AboutPage

export const query = graphql`
  query {
    file(relativePath: { eq: "images/about-me.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

import React from "react";
import Img from "gatsby-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faFlag, faHandshake} 
  from '@fortawesome/free-solid-svg-icons'

function AboutDetails({ data }) {
  return (
    <section className="py-12 md:py-32 overflow-x-hidden">
      <div className="container mx-auto text-center mb-20">
        <h1 className="text-4xl my-10 uppercase font-bold">About Me</h1>
        <p className="text-xl">
          Gone are the days of ugly and outdated websites let me help you make yours the next talk of the town 
          while enjoying the process every step of the way. Please to meet you and welcome to my little space 
          on the web.
        </p>
      </div>
      <div className="container px-4 mx-auto">
        <div className="flex flex-wrap lg:flex-nowrap">
          <div className="w-full lg:w-1/2">
            <div className="py-6 lg:pr-32">
              <div className="mb-4">
                <h2 className="text-4xl mt-3 font-bold font-heading">
                  Goals
                </h2>
              </div>
              <div className="flex items-start py-4">
                <div className="w-8 mr-5 text-blue-500">
                  <FontAwesomeIcon icon={faEye} size="2x" />
                </div>
                <div>
                  <h3 className="mb-2 text-xl font-semibold font-heading">
                    Vision
                  </h3>
                  <p className="text-blueGray-400 leading-loose">
                    To help modernize the web one site at a time using the latest technologies and practices.
                  </p>
                </div>
              </div>
              <div className="flex items-start py-4">
                <div className="w-8 mr-5 text-blue-500">
                  <FontAwesomeIcon icon={faFlag} size="2x" />
                </div>
                <div>
                  <h3 className="mb-2 text-xl font-semibold font-heading">
                    Mission
                  </h3>
                  <p className="text-blueGray-400 leading-loose">
                    To create quality websites and web applications that bridge the gap between design and technology.
                  </p>
                </div>
              </div>
              <div className="flex items-start py-4">
                <div className="w-8 mr-5 text-blue-500">
                  <FontAwesomeIcon icon={faHandshake} size="2x" />
                </div>
                <div>
                  <h3 className="mb-2 text-xl font-semibold font-heading">
                    Values
                  </h3>
                  <p className="text-blueGray-400 leading-loose">
                    I standy by delivering quality, simplicity, and innovative solutions for your website while being honest, trustworthy, and integral.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="relative w-full lg:w-1/2 my-12 lg:my-0">
            <Img className="relative mx-auto rounded-xl w-full z-10"
              fluid={data.file.childImageSharp.fluid} />
            <img
              className="absolute top-0 left-0 w-40 -ml-12 -mt-12"
              src="metis-assets/elements/blob-tear.svg"
              alt
            />
            <img
              className="absolute bottom-0 right-0 w-40 -mr-12 -mb-12"
              src="metis-assets/elements/blob-tear.svg"
              alt
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutDetails;
